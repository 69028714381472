
.Slider-Component{
    width: 225px;
    height: 60px;
    position: relative;
    border-radius: 50px;
    /* transition: all 1s ease-in-out; */
    overflow: hidden;
}

.contenedor {
  width: 225px;
  height: 60px;

  position: relative;
  background-color: #ccc;
  margin-bottom: 10px;
}

.circulo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3498db;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: left 0.2s ease;
}


.Slider-Component > div{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  /* border: 1px solid var(--gray-600, #545962); */
  background: var(--secondary-100, #FFF);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 10px;
  touch-action: manipulation; /* Mejora la experiencia táctil */
  z-index: 10;


}
.test{
  background-color: blue;
  height: 100px;
  width: 100px;
  position: relative;
}

.Slider-Component-OFF{
    border: 1px solid var(--gray-600, #545962);
    background: var(--gray-800, #232A35);
}

.Slider-Component-ON{
    border: 1px solid transparent;
    background: var(--2, linear-gradient(35deg, #1E76BB 0%, #24CFFF 100%));
}




.Slider-Icon{
    position: relative;
    width: 100%;
    height: 100%;
    

}

.Slider-Icon > svg{
    transition: 0.8s ease-in-out all;

}


.Slider-Icon-rotate{
    position: absolute;
    left: 13px;
    top: 15px;
    transform: rotate(90deg);
}

.Slider-Icon > svg:last-child{
    position: absolute;
    left: 11px;
    top: 12px;
}



.Slider-Component > span{
    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: #FEFEFF;
    transition: 0.8s ease-in-out all;


}

.Slider-Component  span:first-child{
    position: absolute;
    top: 18px;
    right: 35px;

}

.Slider-Component  span:last-child{
    position: absolute;
    top: 18px;
    left: 35px;

}

.Slider-Component-Text-ON{
    opacity: 1;
}

.Slider-Component-Text-OFF{
    opacity: 0;
}





/* animacion */

.line-animation span {
  
    position: absolute;
    display: block;
  }

  .line-animation span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 50%;
    height: 3px;
    background: linear-gradient(90deg, transparent, #fff);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }
  
.line-animation span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 3px;
    height: 50%;
    background: linear-gradient(180deg, transparent, #fff);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s 
  }
  
   @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .line-animation span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 50%;
    height: 3px;
    background: linear-gradient(180deg, transparent, #fff);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .line-animation span:nth-child(4) {
    bottom: -200%;
    left: 0;
    width: 3px;

    background: linear-gradient(180deg, transparent, #fff);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -200%;
    }
    50%,100% {
      bottom: 100%;
    }
  }


