
.PrincipalButton{
    border: none;
    border-radius: 10px;
    background: var(--primary-900, #0D98E5);
    box-sizing: border-box;
    height: 54px;
    width: 95%;
    cursor: pointer;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    padding-bottom: 10px;

    
}

.BlueVersion{
    background: var(--primary-900, #0D98E5);
    color: #fff;
}

.WhiteVersion{
    background: #fff;
    color: var(--primary-900, #0D98E5);
}

