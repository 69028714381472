.InputTimeSelect{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 14px;
}

.InputTimeSelect > label{
    color: var(--gray-900, #141D2F);
    leading-trim: both;
    text-edge: cap;
    /* base/Medium */

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.InputTimeSelect > div{
    width: 100%;
    position: relative;
}

.InputTimeSelect > div > input{
    width: 100%;
    height: 54px;
    border-radius: 10px;
    border: 1px solid var(--gray-50, #E7E7E9);
    background: var(--primary-100, #FFF);

    /* El color se trabajara luego ya que hay que cambiarlo en el darkmode */
    color: var(--gray-400, #85898F);
    leading-trim: both;
    text-edge: cap;
    /* base/Regular */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding-left: 10px;
}

.InputTimeSelect > div >  svg{
    position: absolute;

    cursor: pointer;
}

.InputTimeSelect > div >  svg:first-child{
    right: 5px;
    top: 15px;
}

.InputTimeSelect > div >  svg:last-child{
    right: 5px;
    top: 40px;
    transform: rotate(180deg);
}

.inputTimeSelect-label{
color: var(--gray-900, #141D2F);
leading-trim: both;
text-edge: cap;
/* base/Medium */
font-size: 16px;
font-style: normal;
line-height: 24px; /* 150% */
font-weight: bold !important;
}