.CardHome-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 60px;
    gap:20px;
    box-shadow: 0px 20px 40px 0px rgba(41, 145, 203, 0.08);
    border: 0.5px solid var(--gray-50, #E7E7E9);
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 70px;
    width:"250px"!important;
    transform: scale(0.8);

}

.CardHome-info{
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap:5px;
}

.CardHome-image{
    width: 140px;
    height: 200px;

}

.CardHome-image > img{
    width: 100%;
    object-fit:contain;
}

.CardHome-container-Selected{
    transform: scale(1);
}
