.InputText-Component{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2px;
}

.InputText-Component > label{
    /* color: var(--gray-900, #141D2F); */

    /* base/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px; /* 150% */


}

.InputText-Component > div{
    width: 100%;
    position: relative;
}

.InputText-Component > div > input{
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    border-radius: 10px;
    border: 1px solid var(--gray-50, #E7E7E9);
    background: transparent;

    /* El color se trabajara luego ya que hay que cambiarlo en el darkmode */
    color: var(--gray-400, #85898F);
    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    padding: 0 10px;
}

.InputText-Component > div > div{
    position: absolute;
    right: 16px;
    top: 20px;
    cursor: pointer;
}

.InputText-Component > p{
    color: var(--red-500, #FF5C5C);

    /* base/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
}