.ConnectorStatus{
    border: none;
    background-color: #E7F5FC;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    width: 140px;
    height: 32px;

    color: var(--primary-900, #0D98E5);
    text-align: center;

    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

}
