.ChargerPhoto-Component{
    width: 135px;
    height: 180px;
    border-radius: 20px;
    border: 0.5px solid var(--gray-50, #E7E7E9);

}

.ChargerPhoto-Component > img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;

}