.App {
  text-align: center;
}

.simpleText{
  color: var(--gray-400, #85898F);
  text-align: center;

  /* base/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.simpleText > b{
  color: var(--gray-900, #141D2F);
  text-align: center;
  /* base/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */


}


.simpleText > b > a{
  color: var(--gray-900, #141D2F);

  text-decoration: none;
}


.splitDiv{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}
