

.InputCheck-componentSingle{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.InputCheck-label{
    color: var(--gray-900, #141D2F);

    /* base/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 150% */
}

.InputCheck-checks > input{
    border-radius: 5px;
       border: 2px solid #888;
    background: var(--secondary-100, #FFF);

    width: 20px;
    height: 20px;

}


.InputCheck-checks{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: space-between;
    justify-content: space-evenly;
    gap: 18px;
    width: 100%;
}

.InputCheck-componentMultiple{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.InputCheck-check > div{
    width: 20px;
    height: 20px;

    border-radius: 5px;
    border: 1.5px solid var(--primary-900, #0D98E5);
    cursor: pointer;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
}


.InputCheck-check > span{

    color: var(--gray-400, #85898F);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
}