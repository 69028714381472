.ListOption-Component{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #E7E7E9;

    padding: 8px 0px;
}

.ListOption-labels{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    align-items: center;


    color: var(--gray-400, #85898F);

    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}