.ChargerSettings-layout{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 20px 60px 20px ;
}

.chargerActive{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
}

.sliderContent{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap:7px;
}

.TimeCharger-form{
    display:flex;
    width: 100%;
    flex-direction: column;
    flex-wrap:wrap;
    gap: 7px;
    margin-bottom: 25px;
}