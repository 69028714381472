

.HeaderBack{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    /* justify-content: space-around; */
    padding: 22px 0 ;
    position: relative;
    width: 100%;
}

.HeaderBack > svg{

    margin-left: 30px;

}

.HeaderBack > div{
    /* color: var(--gray-900, #141D2F); */
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /* xl/Semibold */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */   
    position: absolute;
    z-index: 1;
    width: 100%; 
    height: 100%;
    top: 15px;
}


.HeaderBack > div > span{
    margin-top: 10px;
}
