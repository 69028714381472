
.Steps-component{
    padding: 22px 0 ;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: space-around;
}

.Steps-List{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    gap: 8px;
}

.Steps-item{
    width: 52px;
    height: 8px;

    border-radius: 50px;
    /* background: #E7E7E9; */
    transition: all 1s ease-in-out;

}