.Charger-layout{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        padding: 0px 20px 60px 20px ;
        width: 100%;
}

.Charger-title{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}