.Notification-Component{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;

    align-items: center;
    /* background-color: red; */

}

.Notification-Component > div{
    width: 48px;
    height: 48px;
    border-radius: 50%;


    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.Notification-Component > span{
    color: var(--gray-400, #85898F);
    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    
    width: calc(100% - 48px);
}