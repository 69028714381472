.SubTitle{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding:22px 0;
}

.subtitle{
    color: var(--gray-400, #85898F);
text-align: right;
/* base/Regular */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.title{
/* xl/Semibold */
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 140% */
}