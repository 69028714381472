.ChargerCard-Component{
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--gray-50, #E7E7E9);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    position: relative;

}

.ChargerCard-Date{
    position: absolute;
    box-sizing: border-box;
    top: 0;
    right: 0;
    padding: 10px;

    border-radius: 4px;
    background: var(--secondary-900, #23B574);


    color: var(--primary-100, #FFF);
    text-align: center;
    /* xs/Regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

}

.ChargerCard-Component > img{
    width: 100%;
    height: 180px;
    object-fit: cover;

}

.ChargerCard-Component > span{
    color: var(--gray-900, #141D2F);
    text-align: center;
    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.ChargerCard-SubComponent{
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 49px;
    text-align: center;


    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

}


.ChargerCard-SubComponent-Available{
    background: var(--primary-900, #0D98E5);
    color: var(--secondary-100, #FFF);

}

.ChargerCard-SubComponent-Disabled{
    color: var(--gray-400, #85898F);
    border: 1px solid var(--gray-50, #E7E7E9);
}