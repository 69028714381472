.Login-layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 90px 30px 60px 30px;

  align-items: center;
}

.LoginTextSide {
  width: 100%;
  color: var(--gray-400, #85898f);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.LoginTextSide div {
  background: var(--gray-50, #e7e7e9);
  width: 90px;
  height: 1px;
  flex-shrink: 0;
}

.Login-Buttons {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
