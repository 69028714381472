.RegisterSelectCharger-Component{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    padding: 30px 30px 60px 30px ;

    gap: 12px;
}

.RegisterSelectCharger-List{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    gap: 12px;
    margin-bottom: 30px;
}