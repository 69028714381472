
.RangeTime-Component{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;   
}

.RangeTime-Time{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

}


.RangeTime-Time > span{
    color: var(--gray-400, #85898F);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */

}

.rangeTimeSubTitle{
color: var(--gray-900, #141D2F);
leading-trim: both;
text-edge: cap;
/* base/Medium */
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
font-weight: bold;
}