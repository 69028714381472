

.QRIcon{
    position: relative;
}

.QRAnimation{

transition: 1s all ease-in-out;


}