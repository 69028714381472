.SecundaryButton{
    border: none;
    background-color: transparent;
    border: 1.5px solid var(--primary-900, #0D98E5);

    min-width: 267px;
    height: 56px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;


    color: var(--primary-900, #0D98E5);
    text-align: center;

    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

}


.SecundaryButton > svg{
    width: 18px;
    height: 18px;
}


.SecundaryButton > svg > path{
    stroke: var(--primary-900, #0D98E5);
}