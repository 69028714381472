body, button, input, textarea, select, div, span, label {
  margin: 0;
  font-family: 'CustomPoppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: none;
}

code {
  font-family: 'CustomPoppins', sans-serif;
}
