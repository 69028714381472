.SubText{
    color: var(--gray-400, #85898F);
    text-align: center;

    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

}