.DataList-layaout{
    width: 100%;
}

.DataList-list{
    /* color: var(--gray-900, #141D2F); */
    font-size: 18px;
    display: flex;
    margin-bottom: 8px;
    leading-trim: both;
    text-edge: cap;
    /* lg/Medium */
    font-style: normal;
    font-weight: 550;
    line-height: 28px; /* 155.556% */
}

.DataList-options{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.DataList-option{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--gray-400, #85898F);
    border-bottom: 1px solid var(--gray-100, #D1D5DB);
    leading-trim: both;
    text-edge: cap;
    /* base/Regular */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.DataList-boldOption{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* color: var(--gray-900, #141D2F); */
    leading-trim: both;
    text-edge: cap;
    /* base/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-bottom: 1px solid var(--gray-100, #D1D5DB);
}