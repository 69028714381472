.AllSettings-layout{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 20px 60px 20px;
    gap: 15px;
}


