.PaymentMethod-layout{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 20px 60px 20px ;
    margin-top: 35px;
    width: 100%;
}

.label-payment{
    /* color: var(--gray-900, #141D2F); */

    /* base/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px; /* 150% */
}

.formPaymentMethod{
    width: 100%;
}
