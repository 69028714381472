
.SliderBasic-Component{
    /* width: 225px; */
    width: 100%;
    height: 60px;
    position: relative;
    border-radius: 50px;
    /* transition: all 1s ease-in-out; */
}


.Slider-Component-OFF{
    border: 1px solid var(--gray-600, #545962);
    background: var(--gray-800, #232A35);
}

.Slider-Component-ON{
    border: 1px solid transparent;
    background: var(--primary-900, #0D98E5);
}


.SliderBasic-Component > div{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 1px solid var(--gray-600, #545962);
    background: var(--secondary-100, #FFF);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 10px;
    /* left: calc(100% - 50px)); */
    transition: 0.8s ease-in-out all;

}









