.CongratsView-Component{
    box-sizing: border-box;

    width: 100%;
    /* background-color: #fff; */
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}



@media (min-width:700px){
    .CongratsView-Component{
        box-sizing: border-box;

        width: 698px;
        height: 100%;
 
    }
}