
.SplashScreen-page{
    background: var(--primary-900, #0D98E5);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;



}

.SplashScreen-page > span{
    color: #FEFEFF;
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    /* 4xl/Medium */
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px; /* 138.889% */
    padding: 0 20px;
    width: 60%;
}

.SplashScreen-page > p{
    color: #FEFEFF;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    padding: 0 20px;
    width: 60%;
}

.SplashScreen-page > .Logo{
    width: 45%;
}

.SplashScreen-page > .SplashScreen-image{
    width: 85%;
}