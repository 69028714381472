
.PageSection{
    width: 100%;
    height: 100vh;
}

.PageSection > div{
    min-height: 100vh;
    width: 100%;

}

.PageSection-dark{
    background-color: #141D2F;
    color: #fff !important;
}

.PageSection-light{
    background-color: #fff;
    color: #000!important;
}


@media (min-width:700px){
    .PageSection{
        box-sizing: border-box;
        margin: 0 auto;
        width: 700px;
        height: 100%;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
    }
}