
.ProfilePicture-Component{
    width: 115px;
    height: 115px;
    border-radius: 50%;
    overflow: hidden;
}

.ProfilePicture-Component > img{
    width: 100%;
    border-radius: 50%;

    object-fit: contain;

}