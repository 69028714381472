.SocialMediaButton-Component {
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--gray-50, #e7e7e9);
  background-color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 54px;

  width: 175px;

  color: var(--gray-900, #141d2f);

  /* base/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
